.grad-btn {
  filter: hue-rotate(360deg);
  transition: border-color 0.3s;
  background-image: linear-gradient(to right, #05f0ff 0%, #db00ff 100%),
    linear-gradient(to right, #05f0ff 0%, #db00ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-image-slice: 1;
  animation: huerotate 6s infinite linear;
}

@keyframes huerotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

footer {
  z-index: 999;
  position: absolute;
}
