@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MainTitle';
  font-style: normal;
  font-weight: 700;
  src: url('/public/font/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SubTitle';
  /* font-style: italic; */
  font-weight: 700;
  src: url('/public/font/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SubDetail';
  font-style: normal;
  font-weight: 400;
  src: url('/public/font/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SubText';
  /* font-style: italic; */
  font-weight: 600;
  src: url('/public/font/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SemiTitle';
  font-style: normal;
  font-weight: 600;
  src: url('/public/font/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SemiText';
  font-style: normal;
  font-weight: 300;
  src: url('/public/font/Montserrat-VariableFont_wght.ttf');
}

video {
  background: transparent;
  outline: none;
  border: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  clip-path: inset(1px 1px);
}

video:focus {
  background: transparent;
  outline: none;
  border: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  clip-path: inset(1px 1px);
}
