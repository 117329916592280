body {
  overflow-x: auto;
  overflow-y: auto;
  margin: 0;
}

h1 {
  /* color: rgba(56, 56, 56, 0.4); */
  color: #fff;
  transition: color 0.7s ease-in-out;
}

h1.scrolled {
  color: #fff;
}

.inner {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  z-index: 17;
}

.outer::-webkit-scrollbar {
  display: none;
}

.divider {
  width: 5px;
  height: 100%;
  background-color: black;
}

.divider-mobile {
  height: 10vh;
  width: 100%;
  background-color: black;
}
